import useInvestmentsDetails from "../../../hooks/useInvestmentsDetails";
import FundsSidebar from "./FundsSidebar";
import "./InvestmentsDetails.scss";
import FundsDetails from "./FundDetails/FundsDetails";
import InvestorsDetails from "./InvestorDetails/InvestorsDetails";
import { IInvestmentsDetailsProps } from "./IInvestmentsDetails";
import ModalTvpi from "./FundDetails/ModalTvpi";


const InvestmentsDetails = (props: IInvestmentsDetailsProps) => {
  const { translations, language, mainColor } = props;
  const {
    loading,
    peipsList,
    selectedPeip,
    handleFundClick,
    filteredInvestorList,
    filterInvestors,
    detailsBy,
    fundsData,
    downloadExcel,
    quarterList,
    onChangeDates,
    selectedDates,
    selectedInstitution,
    selectedMode, 
    handleInvestorModeClick,
    toggleRow,
    investorVehicleData,
    handleChartSelection,
    cashflowChartData,
    handleChangeDetailsBy,
    navChartData,
    tvpiChartData,
    selectedPeipRadio,
    handleSelectedVehicleRadio,
    netAssetValueChart,
    vehiclesSelectedChart,
    investorFundData,
    onOpenTVPIModal,
    onCloseTvpiModal,
    isTvpiModalOpen
  } = useInvestmentsDetails();

  return (
    <div className="main-details">
      <ModalTvpi
        isTvpiModalOpen={isTvpiModalOpen}
        onCloseTvpiModal={onCloseTvpiModal}
        tvpiChartData={tvpiChartData}
      />
      <FundsSidebar
        translations={translations}
        loading={loading}
        peipsList={peipsList}
        filteredInvestorList={filteredInvestorList}
        selectedPeip={selectedPeip}
        selectedInstitution={selectedInstitution}
        handleFundClick={handleFundClick}
        filterInvestors={filterInvestors}
        detailsBy={detailsBy}
        handleChangeDetailsBy={handleChangeDetailsBy}
      />
      {detailsBy === "funds" && (
        <FundsDetails
          translations={translations}
          fundsData={fundsData}
          downloadExcel={downloadExcel}
          onChangeDates={onChangeDates}
          quarterList={quarterList}
          selectedDates={selectedDates}
          loading={loading}
          selectedPeip={selectedPeip}
          navChartData={navChartData}
          tvpiChartData={tvpiChartData}
          mainColor={mainColor}
          onOpenTVPIModal={onOpenTVPIModal}
        />
      )}
      {detailsBy === "investors" && (
        <InvestorsDetails
          translations={translations}
          selectedInstitution={selectedInstitution}
          selectedMode={selectedMode}
          handleInvestorModeClick={handleInvestorModeClick}
          toggleRow={toggleRow}
          downloadExcel={downloadExcel}
          language={language}
          investorVehicleData={investorVehicleData}   
          investorFundData={investorFundData}
          loading={loading}
          handleChartSelection={handleChartSelection}
          cashflowChartData={cashflowChartData}
          selectedPeipRadio={selectedPeipRadio}
          handleSelectedVehicleRadio={handleSelectedVehicleRadio}
          netAssetValueChart={netAssetValueChart}
          vehiclesSelectedChart={vehiclesSelectedChart}
          mainColor={mainColor}
        />
      )}
    </div>
  );
};

export default InvestmentsDetails;
