import { LogoutOutlined } from "@ant-design/icons";
import "./UnderMaintenance.scss";
import { useParams } from "react-router-dom";

const UnderMaintenance = (props: any) => {
  const { logout } = props;
  const { lang } = useParams();

  let title = lang === "es" ? "Actualmente estamos en mantenimiento" : "We are currently under maintenance";;

  return (
    <div className="under-maintenance">
      <div className="under-maintanance-header">
        <img
          height="30px"
          src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/reporting/new_logo.png"
          alt="qualitas-funds"
        />
        <button onClick={logout} className="logout-btn">
          Logout <LogoutOutlined />
        </button>
      </div>
      <div className="under-maintanance-body">
        <h2>{title}</h2>
        <img
          height="350px"
          src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/undermaintenance.png"
          alt="under-maintenance"
        />
        {lang === "es" ? (
          <p>
            Si desea seguir navegando en el Portal del Inversor, <br /> por
            favor haga <a href="https://investor.qualitasfunds.com/">click aquí</a> para redirigirse a la web
            operativa.
          </p>
        ) : (
          <p>
            If you wish to continue browsing the Investor Portal, <br /> please
            click <a href="https://investor.qualitasfunds.com/">here</a> to be redirected to the operational
            website.
          </p>
        )}
      </div>
    </div>
  );
};

export default UnderMaintenance;
