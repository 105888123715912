import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'typeface-playfair-display';
import { Layout } from 'antd';

import { PrivateRoute } from '@qualitas/auth';
import { useFeedbackModal } from '@qualitas/feedback-modal';

import useApp from '../../hooks/useApp';
import { Footer } from '@qualitas/navbar';

import MenuNavbar from '../../components/Menu/MenuNavbar';
import NavigationMenu from '../../components/NavigationMenu/NavigationMenu';
import Home from '../Sections/Home/Home';
import InvestmentsDetails from '../Sections/InvestmentsDetails/InvestmentsDetails';
import Documents from '../Sections/Documents/Documents';
import YourInvestments from '../Sections/YourInvestments/YourInvestments';
import Media from '../Sections/Media/Media';

import '../../App.scss';
import Welcome from './Welcome';

const InvestorPortalApp = ({
	validateAuthToken,
	userIdInstitution,
	userName,
	userEmail,
	userContactId,
	logOut,
	getAuthToken,
	baseUrl,
	authProps,
	translations,
	language,	
	setLanguage, 
	userPrimaryColor,
	userLogo
}: any) => {
	const feedbackModalProps = useFeedbackModal({});
	const navigate = useNavigate();	
	let location = useLocation();

	const {
		welcome,
		mainColor,
		inLineStyle,
		firstEntrance, 
		setFirstEntrance	
	} = useApp({location, userPrimaryColor, userLogo, validateAuthToken});

	

	return (
		<div className="investor-portal-app">
			{welcome ? 
				<Welcome 
					mainColor={mainColor} 
					translations={translations} 
					logo={userLogo}
					/>
				: 
			<Layout style={ inLineStyle }>
				{!welcome && 
					<MenuNavbar 
						getAuthToken={getAuthToken} 
						baseUrl={baseUrl} 
						logout={logOut}
						authProps={authProps}
						sendResetPasswordEmail={authProps.sendResetPasswordEmail}
						navigate={navigate}
						setLanguage={setLanguage}
						language={language}
					/>}
				<Routes>
					<Route element={<PrivateRoute validateAuthToken={validateAuthToken}/>}>
						<Route path="/" element={
							<NavigationMenu
								translations={translations} 
								children={
									<Home
										translations={translations}
										language={language}
										mainColor={mainColor}
										firstEntrance={firstEntrance}
										setFirstEntrance={setFirstEntrance}									
									/>
								}
							/>
						}/>
						<Route path="/Details" element={
							<NavigationMenu 
								translations={translations}  
								children={ <InvestmentsDetails translations={translations} language={language} mainColor={mainColor}/>} 
							/>
						}/>
						<Route path="/YourInvestments/*" element={
							<NavigationMenu 
								translations={translations} 
								children={
									<YourInvestments
										translations={translations}
										userContactId={userContactId}
										userIdInstitution={userIdInstitution}
										language={language}
										baseUrl={baseUrl}
										getAuthToken={getAuthToken}
										showServiceFeedbackModal={feedbackModalProps.showServiceFeedbackModal}
									/>} 
							/>
						}/>
						<Route path="/Media" element={
							<NavigationMenu 
								translations={translations} 
								children={
								<Media 
									translations={translations}
									language={language}
								/>} 
							/>
						}/>

						<Route path="/Documents" element={
							<NavigationMenu 
								translations={translations} 
								children={
									<Documents
										language={language}
										translations={translations}
										getAuthToken={getAuthToken}
										appName="Investor Portal"
										userEmail={userEmail}
										mainColor={mainColor}
									/>
								}
							/>
						}/>
					</Route>
					<Route path="*" element={<Navigate to='/'/>}/>
				</Routes>
				<Footer language={language}/>
			</Layout>
			}
		</div>
	);
}

export default InvestorPortalApp;
