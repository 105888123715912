import { DotChartOutlined, DownloadOutlined, LineChartOutlined, LoadingOutlined } from "@ant-design/icons";
import InvestorsTable from "./InvestorsTable";
import CashflowChart from "./CashflowChart";
import useDocumentGeneration from "../../../../hooks/useDocumentGeneration";
import { IInvestorDetailsProps } from "../IInvestmentsDetails";
import { Skeleton, Spin } from "antd";
import NetAssetValueChart from "./NetAssetValueChart";

const InvestorsDetails = (props: IInvestorDetailsProps) => {
  const {
    translations,
    selectedInstitution,
    selectedMode,
    handleInvestorModeClick,
    downloadExcel,
    toggleRow,
    language,
    investorVehicleData,
    loading,
    handleChartSelection,
    cashflowChartData,
    handleSelectedVehicleRadio,
    selectedPeipRadio,
    netAssetValueChart,
    vehiclesSelectedChart,
    investorFundData,
    mainColor
  } = props;

  const {
    viewReport,
    documentLoading,
  } = useDocumentGeneration({
      selectedInvestors: {idVehicle: 0, investors: []}, 
      language
});

  return (
    <div className="investor-details">
      <div className="investors-header">
          <h2>
            {translations.detallePorInversor} | {selectedInstitution.institution}
          </h2>
        <button className="download-btn" onClick={() => downloadExcel("investor-table")}>
          <DownloadOutlined /> {translations.descargarExcel}
        </button>
      </div>
      <div className="investor-btns-container">
        {["positionValue", "movementDetails"].map((mode: string) => (
          <button
            key={mode}
            className={`mode-btn ${selectedMode === mode ? "selected-mode" : ""}`}
            onClick={() => handleInvestorModeClick(mode)}
          >
            {translations[mode === "positionValue" ? "valorPosicion" : "detalleMovimientos"]}
          </button>
        ))}
      </div>
      <InvestorsTable 
        selectedMode={selectedMode} 
        translations={translations} 
        toggleRow={toggleRow}
        investorFundData={investorFundData}
        investorVehicleData={investorVehicleData}
        loading={loading}
        handleChartSelection={handleChartSelection}
        viewReport={viewReport}
        documentLoading={documentLoading}
        selectedPeipRadio={selectedPeipRadio}
        handleSelectedVehicleRadio={handleSelectedVehicleRadio}
        vehiclesSelectedChart={vehiclesSelectedChart}
        mainColor={mainColor}
        />
        <div className="netasset-chart-container">
          <Spin
            size="large"
            spinning={loading.chart || loading.table}
            tip={translations.loadingChart}
            style={{color: mainColor}}
            indicator={<LineChartOutlined style={{ fontSize: 46, color: mainColor }} />}
            >
              {((loading.table) && <Skeleton active style={{ width: "100%", height: "400px" }} />)}
              {/* CHARTS */}
              {(!loading.table && selectedMode === 'movementDetails' && vehiclesSelectedChart.length > 0) && 
                <CashflowChart 
                  chartData={cashflowChartData}
                  translations={translations}
                  loading={loading.chart ?? false}
                  mainColor={mainColor}
              />} 
              {(selectedMode === "positionValue" && !loading.table && selectedPeipRadio !== 0) && (
				<>
					<NetAssetValueChart 
					translations={translations} 
					netAssetValueChart={netAssetValueChart}
					loading={loading.table}
					/>
					<span className="valuation-disclaimer">* {translations.netAssetDisclaimer}</span>
				</>
              )}
              {(!loading.chart && !loading.table && selectedPeipRadio === 0 && selectedMode === "positionValue") && 
                <div className="empty-data empty-data-grey">
                    <p>
                        {translations.noPeipSelected}
                    </p>
                    <Skeleton.Node >
                        <DotChartOutlined style={{ fontSize: 40, color: mainColor }} />
                    </Skeleton.Node>
                </div>
              }
              {(!loading.chart && !loading.table && vehiclesSelectedChart.length === 0 && selectedMode === 'movementDetails') && 
                <div className="empty-data empty-data-grey">
                    <p>
                        {translations.noInvestorsSelected}
                    </p>
                    <Skeleton.Node >
                        <DotChartOutlined style={{ fontSize: 40, color: mainColor }} />
                    </Skeleton.Node>
                </div>
              }
          </Spin>    
        </div>
    </div>
  );
};

export default InvestorsDetails;
