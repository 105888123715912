const languages = {
    es:{
      idioma: "Idioma",
      inversores: "Inversores",
      docs: "Docs",
      documentos: "Documentos",
      analiza: "Analiza tu inversión",
      cargando: "Cargando datos...",
      buscar: "Buscar inversor",
      busquedaHolder: "BUSQUE POR NOMBRE",
      descargarExcel: "Descargar Excel",
      grupo: "Grupo",
      compromiso: "Compromiso",
      compromisoGrafico: "Comp.",
      contribuciones: "Contribuciones",
      distribuciones: "Distribuciones",
      participaciones: "Participaciones",
      tuInversion: "Resumen",
      documentosInversores: "Documentos de inversores",
      nombreDocumento: "Nombre del Documento",
      fondos: "Fondos",
      fof: "Fondo de Fondos",
      coinvestFunds: "Fondos de coinversión",
      anuncios: "Anuncios",
      portalInversor: "Portal del inversor",
      contacto: "Contacto",
      accesoDenegado: "Acceso denegado",
      accesoDenegadoTexto: "El usuario actual no tiene acceso a esta aplicación",
      SeleccionaDocumento: "Selecciona un documento",
      ampliar: "Ampliar",
      seleccionarTodos: "Seleccionar Todos",
      diaInversor: "Día del Inversor",
      verTutorial: "Ver tutorial",
      descargarInfo: "Ver info",
      verEvento: "Ver evento",
      presentacion: "Presentación",
      noMostrarMas: "No volver a mostrar",
      planifica: "Planifica tu inversión",
      videos: "Vídeos",
      ayuda: "Ayuda",
      dataRoom: "Data Room",
      enlarge: 'Pantalla completa',
      download: 'Descargar',
      fondoSeleccionado: "Fondo seleccionado",
      noInvestorsAsociados: "No hay inversores asociados a este usuario",
      desembolsos: "Desembolsos",
      pendiente: "Pendiente",
      valoracion: "Valoración",
      verInforme: "Ver informe",
      bienvenido: "Bienvenido al Portal del Inversor",
      resumen: "Resumen",
      media: "Media",
      continuationFinance: "Fondos de deuda",
      separateManagedAccounts: "Cuentas segregadas",
      ultimaValoracion: "Última valoración disponible a",
      buscarPorInvesor: "Buscar por inversor",
      buscarPorFondo: "Buscar por fondo",
      principalesMagnitudes: "Evolución de las principales magnitudes del Fondo",
			trimActual: "Trimestre actual",
			trimAnterior: "Trimestre anterior",
			variacion: "Variación",
      compromisoTotal: "Total patrimonio comprometido",
      desembolsoDeParticipes: "Desembolso de partícipes",
      distribucionesAcumuladas: "Distribuciones acumuladas",
      compromisoPendiente: "Compromiso pendiente",
      compromisoTotalFondos: "Total comprometido en fondos",
      desembolsoFondosSubyacentes: "Desembolso en fondos subyacentes",
      distribucionesRecibidasFondo: "Distribuciones recibidas por el Fondo",
      valoracionCarteraTotal: "Valoración de la Cartera",
      posicionNetaCaja: "Cuenta neta de la caja",
      valorTotalActivoNeto: "Valor total de la inversión (NAV)",
      multiploDistribucionCapital: "Múltiplo de distribución (DPI)",
      multiploValorResidualCapital: "Múltiplo de valor residual (RPI)",
      multiploTotalValorCapital: "Múltiplo total para el inversor (TVPI)",
      detallePorInversor: "Detalle por Inversor",
      detallePorFondo: "Detalle por Fondo",
      desdeTrimestre: "Desde",
      hastaTrimestre: "Hasta",
      valorPosicion: "Valor posición",
      detalleMovimientos: "Detalle movimientos",
      inversion: "Inversión",
      fecha: "Fecha",
      valorLiquidativo: "Valor liquidativo",
      evolucionValorLiquidativo: "Evolución del valor liquidativo",
      valorLiquidativoProforma: "Valor liquidativo proforma",
      generar: 'Generar',
      certificadoDeParticipacion: 'Certificado de participación',
      certificadoParticipacion: 'Certificado participación',
      solicitudDesembolso: 'Solicitud de desembolso',
      cartaCompromiso: 'Carta de compromiso',
      notificacionDistribucion: 'Notificación de distribución',
      enFecha: 'En fecha',
      generarDocumentos: 'Generar documentos',
      cerrar: 'Cerrar',
      abrirDocumento: 'Abrir documento',
      verDocumentos: 'Ver documentos',
      CapitalCalls: 'Llamada de capital',
      Distribution: "Distribución",
      Transfer: "Transferencia",
      Closing: "Compromiso",
      generandoDocumento: 'Generando documento',
      grafico: 'Gráfico',
      estimacionCashflows: 'Estimación Cashflows',
      otros: 'Otros',
      analisisCartera: 'Análisis de cartera',
      control: 'Control',
      capitalCallNoPago: 'Llamado de capital no pagado. Haga click para más información',
      certificadoDeParticipacionAFecha: 'Certificado de participación a fecha',
      informacionAFecha: 'Información a ',
      porInversor: 'Por inversor',
      porFondo: 'Por fondo',
      navPrevQuarter: "Trimestre anterior",
      valuation: "Valoración",
      netCash: "Caja neta",
      newInvestment: "Nuevas inversiones",
      distribution: "Distribuciones",
      totalNav: "Nav total",
      graficosDelFondo: "Gráficos del fondo",
      generarDocumento: "Generar documentos",
      documentosDelFondo: "Documentos del fondo",
      invesor: 'Inversor',
      seleccioneFormulario: 'Seleccione los documentos que desea generar',
      seleccioneMismoVehiculo: 'Seleccione invesores del mismo vehículo',
      flujos: 'Flujos',
      capitalCalls: "Llamadas de capital",
      distributions: "Distribuciones",
      pending: "Pendiente",
      residualValue: "Valor residual",
      totalValue: "Valor total",
      commitment: "Compromiso",
      acumulado: "Acumulado",
      noInvestorsSelected: "Por favor seleccione al menos un inversor para generar la vista",
      noPeipSelected: "Por favor seleccione un programa para generar la vista",
      actividadReciente: "Actividad reciente",
      leerMas: "Leer más",
      noticias: "Noticias",
      enero: "Enero",
      febrero: "Febrero",
      marzo: "Marzo",
      abril: "Abril",
      mayo: "Mayo",
      junio: "Junio",
      julio: "Julio",
      agosto: "Agosto",
      septiembre: "Septiembre",
      octubre: "Octubre",
      noviembre: "Noviembre",
      diciembre: "Diciembre",
      newsletter: "últimas comunicaciones",
      quarterReport: "Informe trimestrales",
      sinData: "No hay información reciente",
      home: "Inicio",
      privacyPolicy: 'Política de privacidad',
      cookiesPolicy: 'Política de cookies',
      disclaimer: 'Aviso legal',
      allRightsReserved: 'Todos los derechos reservados',
      cargarMas: 'Cargar más',
      fechasCombinadas: "Si se seleccionan múltiples inversores, las fechas en el listado corresponden a todos ellos. Tenga en cuenta que al generar el documento, algunos inversores podrían no figurar si no tienen movimientos en esa fecha específica.",
      noDataFound: "No se han encontrado datos",
      loadingChart: "Cargando gráfico...",
      noPermissions: "Lo sentimos, no tiene permisos para acceder a esta sección",
      welcomeTo: "Bienvenido al",
      investorPortal: "Portal del Inversor",
      sobreCompromisoTotal: "Porcentaje calculado en base al total comprometido",
      sobreeTotalDesembolsado: "Porcentaje calculado en base al total desembolsado",
      analisis: 'Análisis',
      estadoActual: 'Estado actual',
	  informationToday: "Información a",
	  netAssetDisclaimer: "El valor liquidativo proforma se ha calculado ajustando con los desembolsos y distribuciones posteriores a la última fecha de valoración disponible. ",
	  lookingAt: "Viendo",
	  outOf: "de",
	  investors: "inversores",
	  noMoreInvestors: "Se han mostrado todos los inversores",
	  viewMore: "Cargar más",
	  moreThan: "Más de",
	  noResults: "inversores coinciden con los criterios de búsqueda. Por favor, refine su búsqueda para obtener menos resultados.",
    },
    en: {
      idioma: "Language",
      inversores: "Investors",
      docs: "Docs",
      documentos: "Documents",
      analiza: "Analyze your investment",
      cargando: "Loading data...",
      buscar: "Search investor",
      busquedaHolder: "SEARCH BY NAME",
      descargarExcel: "Download Excel",
      grupo: "Group",
      compromiso: "Commitment",
      compromisoGrafico: "Comm.",
      contribuciones: "Amount",
      distribuciones: "Distributions",
      participaciones: "Shares",
      tuInversion: "Resume",
      documentosInversores: "Investor documents",
      nombreDocumento: "Document name",
      fondos: "Funds",
      fof: "Fund of Funds", 
      coinvestFunds: "Coinvest Funds",
      anuncios: "Announcements",
      portalInversor: "Investor portal",
      contacto: "Contact",
      accesoDenegado: "Access denied",
      accesoDenegadoTexto: "The current user does not have access to this application",
      SeleccionaDocumento: "Select a Document",
      fondoSeleccionado: "Selected Fund",
      ampliar: "Open",
      seleccionarTodos: "Select All",
      ayuda: "Help",
      diaInversor: "Investor Day",
      verEvento: "View event",
      descargarInfo: "View info",
      verTutorial: "View tutorial",
      presentacion: "Presentation",
      noMostrarMas: "Don't show again",
      planifica: "Plan your investment",
      videos: "Videos",
      dataRoom: "Data Room",
      enlarge: 'Full screen',
      download: 'Download',
      noInvestorsAsociados: "No investors asociated to this user",
      desembolsos: "Capital Calls",
      pendiente: "Pending",
      valoracion: "Valuation",
      verInforme: "View report",
      bienvenido: "Welcome to the Investor Portal",
      resumen: "Summary",
      media: "Media",
      continuationFinance: "Continuation Finance",
      separateManagedAccounts: "Separate Managed Accounts",
      principalesMagnitudes: "Main figures evolution of the Fund",
      trimActual: "Current quarter",
			trimAnterior: "Previous quarter",
			variacion: "Variation",
      ultimaValoracion: "Last valuation available at",
      buscarPorInvesor: "Search by investor",
      buscarPorFondo: "Search by fund",
      compromisoTotal: "Total commitment to the Fund",
      desembolsoDeParticipes: "Total paid in capital",
      distribucionesAcumuladas: "Cumulative distributions to investors",
      compromisoPendiente: "Unfunded commitment for drawdown",
      compromisoTotalFondos: "Total commitment in portfolio companies",
      desembolsoFondosSubyacentes: "Total invested in portfolio companies",
      distribucionesRecibidasFondo: "Cumulative distributions",
      valoracionCarteraTotal: "Total fair value of the current companies",
      posicionNetaCaja: "Net cash position",
      valorTotalActivoNeto: "Total net asset value (NAV)",
      multiploDistribucionCapital: "Distributions to paid-in-capital (DPI)",
      multiploValorResidualCapital: "Residual Value to paid-in-capital (RPI)",
      multiploTotalValorCapital: "Total Value to paid-in-capital (TVPI)",
      detallePorInversor: "Investor detail",
      detallePorFondo: "Fund detail",
      desdeTrimestre: "From",
      hastaTrimestre: "To",
      valorPosicion: "Account Valuation",
      detalleMovimientos: "Movements details",
      inversion: "Investment",
      fecha: "Date",
      valorLiquidativo: "Net Asset Value",
      generar: 'Generate',
      certificadoDeParticipacion: 'Capital Account',
      certificadoParticipacion: 'Capital Account',
      solicitudDesembolso: 'Capital Call Notice',
      cartaCompromiso: 'Commitment Agreement',
      notificacionDistribucion: 'Distribution Notice',
      enFecha: 'On date',
      generarDocumentos: 'Generate documents',
      cerrar: 'Close',
      abrirDocumento: 'Open document',
      verDocumentos: 'View documents',
      CapitalCalls: 'Capital Call',
      Distribution: "Distribution",
      Transfer: "Transfer",
      Closing: "Commitment",
      generandoDocumento: 'Generating document',
      valorLiquidativoProforma: "Proforma Net Asset Value",
      grafico: 'Chart',
      estimacionCashflows: 'Cashflows estimation',
      otros: 'Others',
      analisisCartera: 'Portfolio analysis',
      control: 'Control',
      capitalCallNoPago: 'Unpaid Capital Call. Click for more information',
      certificadoDeParticipacionAFecha: 'Capital Account as of',
      informacionAFecha: 'Information up to',
      porInversor: 'By investor',
      porFondo: 'By fund',
      navPrevQuarter: "Previous quarter",
      valuation: "Valuation",
      netCash: "Net cash",
      newInvestment: "New investments",
      distribution: "Distributions",
      totalNav: "Total Nav",
      graficosDelFondo: "Fund charts",
      documentosDelFondo: "Funds\' documents",
      invesor: 'Investor',
      seleccioneFormulario: 'Select the documents you want to generate',
      seleccioneMismoVehiculo: 'Select investors of the same vehicle',
      generarDocumento: 'Generate documents',
      flujos: 'Flows',
      commitment: "Commitment",
      capitalCalls: "Capital Calls",
      distributions: "Distributions",
      pending: "Pending",
      residualValue: "Residual Value",
      totalValue: "Total Value",
      acumulado: "Cumulative",
      noInvestorsSelected: "Please select at least one investor to generate the view",
      noPeipSelected: "Please select a program to generate the view",
      evolucionValorLiquidativo: "Net Asset Value evolution",
      actividadReciente: "Recent activity",
      leerMas: "Read more",
      noticias: "News",
      enero: "January",
      febrero: "February",
      marzo: "March",
      abril: "April",
      mayo: "May",
      junio: "June",
      julio: "July",
      agosto: "August",
      septiembre: "September",
      octubre: "October",
      noviembre: "November",
      diciembre: "December",
      newsletter: "latest communications",
      quarterReport: "Quarterly reports",
      sinData: "No recent information",
      home: "Home",
      privacyPolicy: 'Privacy policy',
      cookiesPolicy: 'Cookies policy',
      disclaimer: 'Disclaimer',
      allRightsReserved: 'All rights reserved',
      cargarMas: 'Load more',
      fechasCombinadas: "If multiple investors are selected, the dates in the list correspond to all of them. Please note that when generating the document, some investors may not appear if they have no transactions on that specific date.",
      noDataFound: "No data found",
      loadingChart: "Loading chart...",
      noPermissions: "Sorry, you don't have permissions to access this section",
      welcomeTo: "Welcome to",
      investorPortal: "Investor Portal",
      sobreCompromisoTotal: "Percentage based on total commitment",
      sobreeTotalDesembolsado: "Percentage based on total paid in capital",
      analisis: 'Analysis',
      estadoActual: 'Current state',
	  informationToday: "Information as of",
	  netAssetDisclaimer: "The pro forma net asset value has been calculated by adjusting for capital calls and distributions that occurred after the latest available valuation date.",
	  lookingAt: "Viewing",
	  outOf: "out of",	
	  investors: "investors",
	  noMoreInvestors: "No more investors to show",
	  viewMore: "Load more",
	  moreThan: "More than",
	  noResults: "investors match the search criteria. Please refine your search to get fewer results.",
	}
  }
  export {languages};