import { useEffect, useState } from 'react'
import ReportTypes from '../config/ReportTypes';
import DocumentsService from '../Servicios/documentos.service';
import { message } from 'antd';
import ReportsApi from '../Api/api.Reports';
import dayjs from 'dayjs';
import { ApiResponse, ReportType } from '../Modelos/Investors.model';
import { IUseDocumentGenerationProps } from './IUseInvestorPortal';


const useDocumentGeneration = (props: IUseDocumentGenerationProps) => {
    const {selectedInvestors, language} = props;
    const [documentLoading, setDocumentLoading] = useState<boolean>(false)
	const [selectedDate, setSelectedDate] = useState<string>(dayjs().format('DD/MM/YYYY'))
	const [capitalCallDates, setCapitalCallDates] = useState<string[]>([])
	const [distributionDates, setDistributionDates] = useState<string[]>([])
    const [reportTypeSelected, setReportTypeSelected] = useState<ReportType>(ReportTypes[0])

	useEffect(() => {
		if(selectedInvestors?.idVehicle !== 0 && selectedInvestors?.investors.length > 0) {
			getCapitalCallDates(selectedInvestors);
			getDistributionDates(selectedInvestors)
		}
		if(reportTypeSelected.IdReport !== 4) {
			setSelectedDate('')
		}
	}, [selectedInvestors.idVehicle, selectedInvestors.investors])

 	const handleChangeReport = (e: number) => {
		let report: ReportType = ReportTypes.find((x: ReportType) => x.IdReport === e) || ReportTypes[0];
		setReportTypeSelected(report);
		if(report && report?.IdReport === 4) {
			setSelectedDate(dayjs().format('DD/MM/YYYY'))
		}else{
			setSelectedDate('')
		}
	};

	const viewReport = async(reportType: string, date: string = selectedDate, investors: number[] = selectedInvestors.investors) => {
		setDocumentLoading(true)
		switch(reportType) {
			case "CapitalCalls":
				await viewCapitalCall(date, investors);
				break;
			case "Distribution":
				await viewDistribution(date, investors);
				break;
			case "Closing":
				await viewClosing(investors);
				break;
			case "CapitalAccount":
				await viewCapitalAccount(date, investors);
				break;
		}
	}

	const getCapitalCallDates = async(data: any) => {
		await ReportsApi.GetCapitalCallDates(data).then((res: ApiResponse)=>{
			if(res.success){
				setCapitalCallDates(res.data)
			}else{
				console.log(res.errorMessage)
			}
		});
		setDocumentLoading(false)
	}

	const getDistributionDates = async(data: any) => {
		await ReportsApi.GetDistributionDates(data).then((res: ApiResponse)=>{
			if(res.success){
				setDistributionDates(res.data)
			}else{
				console.log(res.errorMessage)
			}
		});
		setDocumentLoading(false)
	}

	const viewCapitalCall = async(date: string, investors: number[]) => {
		let data = {
			investorIdList: investors,
			date: dayjs(date, 'YYYY/MM/DD').format('YYYY-MM-DD'),
			language: language.toUpperCase()
		}
		await ReportsApi.GenerateCapitalCall(data).then(res=>{
			var blob = new Blob([res], {type: "application/pdf"});
			var link = window.URL.createObjectURL(blob);
			window.open(link);
			setDocumentLoading(false)
		}).catch((_e: any) => {
			setDocumentLoading(false)
		})
	}

	const viewCapitalAccount = async(date: string, investors: number[]) => {
		let data = {
			investorIdList: investors,
			date: dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
			language: language.toUpperCase()
		}
		await ReportsApi.GenerateCapitalAccount(data).then(res=>{
			var blob = new Blob([res], {type: "application/pdf"});
			var link = window.URL.createObjectURL(blob);
			window.open(link);
			setDocumentLoading(false)
		}).catch((_e: any) => {
			setDocumentLoading(false)
		})
	}

	const viewDistribution = async(date: string, investors: number[]) => {
		let data = {
			investorIdList: investors,
			date: dayjs(date, 'YYYY/MM/DD').format('YYYY-MM-DD'),
			language: language.toUpperCase()
		}
		await ReportsApi.GenerateDistributionNotice(data).then(res=>{
			var blob = new Blob([res], {type: "application/pdf"});
			var link = window.URL.createObjectURL(blob);
			window.open(link);
			setDocumentLoading(false)
		}).catch((_e: any) => {
			setDocumentLoading(false)
		})
	}
	const viewClosing = async(investors: number[]) => {
		for (let i = 0; i < investors.length; i++) {			
			await ReportsApi.GenerateClosing(investors[i]).then(res=>{
				window.open(res.data, '_blank');
			}).catch((e: any) => {
				console.log(e)
				setDocumentLoading(false)
			})
			await new Promise(resolve => setTimeout(resolve, 1000)); // Espera 1 segundo antes de la siguiente apertura
		}
		setDocumentLoading(false)

	}

  return {
    viewReport,
	selectedDate,
	documentLoading,
	setSelectedDate,
	handleChangeReport,
	reportTypeSelected,
	capitalCallDates,
    distributionDates,
  }
}

export default useDocumentGeneration